
import { defineComponent, reactive, ref } from "vue";
import { getContract } from "@/core/services/api/contract";
import localstorage from "@/core/services/LocalstorageService";
import { Modal } from "bootstrap";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import {
  filterTableDataBySearch,
  filterTableDataByBeginEnd,
  BeginEndModel,
} from "@/core/utils/tableSearch";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import moment from "moment";
import { Contract } from "@/core/services/responsesModel/contract";
import CreateContractOffer from "./components/CreateContractOffer.vue";
import { useRoute, useRouter } from "vue-router";

// 範本列表資料Modal
interface tableDataModel {
  guid: string;
  name: string;
  createTime: string;
  remark: string;
}

export default defineComponent({
  components: {
    Datatable,
    CreateContractOffer
  },
  setup() {
    // 空間唯一識別
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    // 當前選擇項目
    const currentSelectItem:any = reactive({})
    // 列表資料
    const tableData = reactive<Array<tableDataModel>>([]);
    // 初始列表資料
    const initTableData = reactive<Array<tableDataModel>>([]);
    // 列表標題
    const tableHeader = ref([
      { key: "name", name: "範本名稱", sortable: true },
      { key: "createTime", name: "建立時間", sortable: true },
       { key: "remark", name: "備註", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    // 列表加載中
    const isTableLoadind = ref<boolean>(false);
    // 搜尋輸入值
    const search = ref<string>("");
    // 路由
    const router = useRouter();

    // 取得空間合約樣板
    const handleGetContract = async () => {
      return await getContract(currentSpaceGuid!);
    };

    // 設置列表資料
    const setTableData = async () => {
      // 列表加載中
      isTableLoadind.value = true;
      let contracts = await handleGetContract();
      tableData.splice(0);
      for (const contract of contracts) {
        tableData.push({
          guid: contract.guid,
          name: contract.name,
          createTime: getLocalTimeString(contract.createTime),
          remark: contract.remark
        });
      }
      tableData.sort(function (a, b) {
        return moment(b.createTime).diff(moment(a.createTime));
      });
      setInitTableData(tableData, initTableData);
      // 結束列表加載中
      isTableLoadind.value = false;
    };

    // 設置初始化列表資料
    const setInitTableData = (
      tableData: Array<tableDataModel>,
      initTableData: Array<tableDataModel>
    ) => {
      initTableData.splice(0, initTableData.length, ...tableData);
    };

    // 根據搜尋設置列表資料
    const handleFilterTableDataBySearch = () => {
      filterTableDataBySearch(tableData, initTableData, search.value);
    };

    // 導到新增樣板頁面
    const toAddContractTemplatePage = () => {
      router.push({ name: "addContractTemplate" });
    };

    // 導到修改與檢視樣板頁面
    const toUpdateContractTemplatePage = (item) => {
      router.push({ path: `updateContractTemplate/${item.guid}` });
    };

    // 設置當前項目
    const setCurrentItem = (item) => {
      Object.assign(currentSelectItem,item)
    }

    // 初始化資料
    const init = async () => {
      await setTableData();
    };
    init();

    return {
      search,
      isTableLoadind,
      handleFilterTableDataBySearch,
      tableData,
      tableHeader,
      setTableData,
      toAddContractTemplatePage,
      toUpdateContractTemplatePage,
      setCurrentItem,
      currentSelectItem
    };
  },
});
