import Swal from "sweetalert2/dist/sweetalert2.min.js";

export function SuccesAlert(title: string, confirmButtonText = "是") {
    return Swal.fire({
        title: title,
        icon: "success",
        confirmButtonColor: "#249cad",
        confirmButtonText: confirmButtonText,
    });
}

export function ErrorAlert(title: string, confirmButtonText= "是") {
    return Swal.fire({
        title: title,
        icon: "error",
        confirmButtonColor: "#249cad",
        confirmButtonText: confirmButtonText,
    });
}

export function ConfirmCanncelAlert(
    title: string,
    confirmButtonText= "確定",
    cancelButtonText = "取消",
    text=""
) {
    return Swal.fire({
        title: title,
        icon: "warning",
        text:text,
        showCancelButton: true,
        confirmButtonColor: "#249cad",
        cancelButtonColor: "#F1416C",
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
    });
}

