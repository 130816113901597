<template>
  <el-form
    @submit.prevent="submit()"
    :model="form"
    :rules="rules"
    ref="formRef"
    class="form"
  >
    <!-- 起訖時間 -->
    <label class="fs-6 fw-bold mb-2">
      <span class="required">起訖時間</span>
    </label>
    <el-form-item prop="dateTimeRange">
      <el-date-picker
        v-model="form.dateTimeRange"
        type="datetimerange"
        range-separator="至"
        start-placeholder="開始日期"
        end-placeholder="結束日期"
      >
      </el-date-picker>
    </el-form-item>
    <!-- 合約擁有者 -->
    <label class="fs-6 fw-bold mb-2">
      <span class="required">合約擁有者</span>
    </label>
    <el-form-item prop="contractOwner">
      <el-select
        v-model="form.contractOwner"
        filterable
        placeholder="請選擇使用者"
        style="width: 100%"
      >
        <el-option
          v-for="item in userOptions"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <!-- 合約管理者 -->
    <label class="fs-6 fw-bold mb-2">
      <span class="required">合約管理者</span>
    </label>
    <el-form-item prop="userManagementPermission">
      <el-select
        v-model="form.userManagementPermission"
        filterable
        placeholder="請選擇合約管理者"
        style="width: 100%"
      >
        <el-option
          v-for="item in contractManagerOptions"
          :key="item.value"
          :label="item.text"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <el-checkbox
      v-model="isAddContractOwner"
      label="將新增者加入合約使用者"
    ></el-checkbox>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">建立</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, watch } from "vue";
import { getMembersBySpace } from "@/core/services/api/members";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addContractOwner,getContractOwnerExist } from "@/core/services/api/contractOwner";
import { addContractUser } from "@/core/services/api/contractUser";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import { ConfirmCanncelAlert, SuccesAlert, ErrorAlert } from "@/core/utils/customSwal"

export default defineComponent({
  props: ["currentContract"],
  setup(props) {
    const currentContract: any = reactive({});
    const isAddContractOwner = ref(false);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const userOptions: any = reactive([]);
    // formRef
    const formRef = ref<null | HTMLFormElement>(null);
    // 表單
    const form = reactive({
      dateTimeRange: [],
      userManagementPermission: "",
      contractOwner: "",
    });
    // 合約管理者選單
    const contractManagerOptions = reactive([
      { text: "合約擁有者", value: "Owner" },
      { text: "space Go 管理員", value: "SpaceManager" },
    ]);
    const rules = {
      dateTimeRange: [
        {
          required: true,
          message: "請填選起訖時間",
          trigger: "blur",
        },
      ],
      contractOwner: [
        {
          required: true,
          message: "請選擇合約使用者",
          trigger: "change",
        },
      ],
      userManagementPermission: [
        {
          required: true,
          message: "請選擇合約管理者",
          trigger: "change",
        },
      ],
    };

    const setUserOptions = async () => {
      let members = await getMembersBySpace(currentSpaceGuid!);
      userOptions.splice(0);
      members.forEach((o) => {
        userOptions.push({
          text: `${o.name} (${o.email})`,
          value: o.guid,
        });
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          ConfirmCanncelAlert("您確定要建立合約嗎?").then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                beginTime: getUtcTimeString(form.dateTimeRange[0]),
                endTime: getUtcTimeString(form.dateTimeRange[1]),
                userManagementPermission: form.userManagementPermission,
              };
              const existContractOwners =await getContractOwnerExist( currentSpaceGuid!,currentContract.guid,form.contractOwner,request);
              if(existContractOwners.length>0){
                 let text="";
                 existContractOwners.forEach(o=>{
                     text+=`${getLocalTimeString(o.beginTime)} - ${getLocalTimeString(o.endTime)} \r\n`
                 })
                
                ConfirmCanncelAlert("以下時間範圍內已有重複合約是否要新增?","確定","取消",text).then(async (result)=>{
                  if (result.isConfirmed) {
                    let addContractOwnerRes: any = await addContractOwner(
                  currentSpaceGuid!,
                  currentContract.guid,
                  form.contractOwner,
                  request
                );
                if (isAddContractOwner.value) {
                  await addContractUser(
                    currentSpaceGuid!,
                    currentContract.guid,
                    addContractOwnerRes.guid,
                    form.contractOwner
                  );
                }
                SuccesAlert("建立成功!");
                  }
                })
              }else{
                let addContractOwnerRes: any = await addContractOwner(
                  currentSpaceGuid!,
                  currentContract.guid,
                  form.contractOwner,
                  request
                );
                if (isAddContractOwner.value) {
                  await addContractUser(
                    currentSpaceGuid!,
                    currentContract.guid,
                    addContractOwnerRes.guid,
                    form.contractOwner
                  );
                }
                SuccesAlert("建立成功!");
              }
               
                
                
            }
          });
          
        }
      });
    };

    watch(
      props,
      () => {
        Object.assign(currentContract, props.currentContract);
        console.log("currentContract", currentContract);
      },
      { deep: true }
    );

    const init = async () => {
      await setUserOptions();
    };
    init();

    return {
      form,
      rules,
      formRef,
      contractManagerOptions,
      userOptions,
      isAddContractOwner,
      submit,
    };
  },
});
</script>

<style>
</style>