import ApiService from "@/core/services/ApiService";
import { Contract } from "@/core/services/responsesModel/contract"

// 取得空間合約樣板
export function getContract(spaceGuid: string): Promise<Array<Contract>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Contract`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增合約樣板
export function addContract(spaceGuid,request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Contract`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 根據合約guid取得合約
export function getContractByGuid(contractGuid: string): Promise<Array<Contract>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Contract/${contractGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 更新合約樣板
export function updateContract(spaceGuid,contractGuid: string,request): Promise<Array<Contract>> {
    return new Promise((resolve, reject) => {
        ApiService.patch(`Space/${spaceGuid}/Contract/${contractGuid}`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

