<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <div class="card-title d-flex justify-content-between">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="handleFilterTableDataBySearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
        <button
          type="button"
          class="btn btn-primary"
          @click="toAddContractTemplatePage()"
        >
          新增範本
        </button>
      </div>
    </div>

    <div class="card-body pt-0">
      <div v-if="isTableLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-name="{ row: tableData }">
          {{ tableData.name }}
        </template>
        <template v-slot:cell-createTime="{ row: tableData }">
          {{ tableData.createTime }}
        </template>
         <template v-slot:cell-remark="{ row: tableData }">
          {{ tableData.remark }}
        </template>
        <template v-slot:cell-operate="{ row: tableData }">
          <el-dropdown>
            <span class="el-dropdown-link text-primary">
              功能選單<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="toUpdateContractTemplatePage(tableData)"
                  >檢視與修改</el-dropdown-item
                >
                <el-dropdown-item
                  data-bs-toggle="modal"
                  data-bs-target="#createContractOffer-modal"
                  @click="setCurrentItem(tableData)"
                  >建立合約優惠</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 建立合約優惠modal -->
  <div
    class="modal fade"
    id="createContractOffer-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="createContractOffer-exampleModalLabel">
            建立合約優惠
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <CreateContractOffer :currentContract="currentSelectItem"></CreateContractOffer>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import { getContract } from "@/core/services/api/contract";
import localstorage from "@/core/services/LocalstorageService";
import { Modal } from "bootstrap";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import {
  filterTableDataBySearch,
  filterTableDataByBeginEnd,
  BeginEndModel,
} from "@/core/utils/tableSearch";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import moment from "moment";
import { Contract } from "@/core/services/responsesModel/contract";
import CreateContractOffer from "./components/CreateContractOffer.vue";
import { useRoute, useRouter } from "vue-router";

// 範本列表資料Modal
interface tableDataModel {
  guid: string;
  name: string;
  createTime: string;
  remark: string;
}

export default defineComponent({
  components: {
    Datatable,
    CreateContractOffer
  },
  setup() {
    // 空間唯一識別
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    // 當前選擇項目
    const currentSelectItem:any = reactive({})
    // 列表資料
    const tableData = reactive<Array<tableDataModel>>([]);
    // 初始列表資料
    const initTableData = reactive<Array<tableDataModel>>([]);
    // 列表標題
    const tableHeader = ref([
      { key: "name", name: "範本名稱", sortable: true },
      { key: "createTime", name: "建立時間", sortable: true },
       { key: "remark", name: "備註", sortable: true },
      { key: "operate", name: "操作" },
    ]);
    // 列表加載中
    const isTableLoadind = ref<boolean>(false);
    // 搜尋輸入值
    const search = ref<string>("");
    // 路由
    const router = useRouter();

    // 取得空間合約樣板
    const handleGetContract = async () => {
      return await getContract(currentSpaceGuid!);
    };

    // 設置列表資料
    const setTableData = async () => {
      // 列表加載中
      isTableLoadind.value = true;
      let contracts = await handleGetContract();
      tableData.splice(0);
      for (const contract of contracts) {
        tableData.push({
          guid: contract.guid,
          name: contract.name,
          createTime: getLocalTimeString(contract.createTime),
          remark: contract.remark
        });
      }
      tableData.sort(function (a, b) {
        return moment(b.createTime).diff(moment(a.createTime));
      });
      setInitTableData(tableData, initTableData);
      // 結束列表加載中
      isTableLoadind.value = false;
    };

    // 設置初始化列表資料
    const setInitTableData = (
      tableData: Array<tableDataModel>,
      initTableData: Array<tableDataModel>
    ) => {
      initTableData.splice(0, initTableData.length, ...tableData);
    };

    // 根據搜尋設置列表資料
    const handleFilterTableDataBySearch = () => {
      filterTableDataBySearch(tableData, initTableData, search.value);
    };

    // 導到新增樣板頁面
    const toAddContractTemplatePage = () => {
      router.push({ name: "addContractTemplate" });
    };

    // 導到修改與檢視樣板頁面
    const toUpdateContractTemplatePage = (item) => {
      router.push({ path: `updateContractTemplate/${item.guid}` });
    };

    // 設置當前項目
    const setCurrentItem = (item) => {
      Object.assign(currentSelectItem,item)
    }

    // 初始化資料
    const init = async () => {
      await setTableData();
    };
    init();

    return {
      search,
      isTableLoadind,
      handleFilterTableDataBySearch,
      tableData,
      tableHeader,
      setTableData,
      toAddContractTemplatePage,
      toUpdateContractTemplatePage,
      setCurrentItem,
      currentSelectItem
    };
  },
});
</script>

<style>
</style>